export default [
  {
    pt_BR: 'Instituição de Ensino',
    es_ES: 'Institución educativa',
    en_US: 'Educational Institution',
  },
  {
    pt_BR: 'Empresa/Fábrica',
    es_ES: 'Empresa',
    en_US: 'Manufacture/Business Company',
  },
  {
    pt_BR: 'Governo',
    es_ES: 'Gobierno',
    en_US: 'Government',
  },
  {
    pt_BR: 'Consultoria',
    es_ES: 'Consultante',
    en_US: 'Consultant',
  },
  {
    pt_BR: 'Outro',
    es_ES: 'Otro',
    en_US: 'Other',
  },
];
