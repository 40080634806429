import { useMemo } from 'react';

const useTranslation = ({ translationObject, language }) => {
  const locale = useMemo(
    () =>
      Object.keys(translationObject).reduce(
        (translations, key) => ({
          ...translations,
          [key]: translationObject[key][language],
        }),
        {}
      ),
    [translationObject, language]
  );

  return { locale };
};

export default useTranslation;
