import React from 'react';
import styled from 'styled-components';
import {
  Paper,
  Container,
  TextField,
  FormControl,
  FormControlLabel,
  Select,
  MenuItem,
  Button,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Layout, SEO, SpinnerOverlay } from '../components';
// import { ThemeManagerContext } from 'gatsby-styled-components-dark-mode';
import countries from '../shared/data/countries';
import positions from '../shared/data/positions';
import organizations from '../shared/data/organizations';
import * as translationObject from '../shared/i18n';
import useTranslation from '../shared/hooks/useTranslation';

const URL =
  'https://auto-subscription-endpoint.azurewebsites.net/api/v1/de-lorenzo-software-form';
const GET_PRODUCTS_URL =
  'https://auto-subscription-endpoint.azurewebsites.net/api/v1/de-lorenzo-software-form-get-products';

const initialValues = {
  email: '',
  ProductId: 0,
  promoCode: '',
  fullName: '',
  phone: '',
  country: '',
  position: '',
  organization: '',
  companyId: '',
};

const optionalFields = ['promoCode'];

export default () => {
  // const theme = React.useContext(ThemeManagerContext);
  const [language, setLanguage] = React.useState('en_US');
  const { locale } = useTranslation({
    translationObject,
    language,
  });
  const [form, setForm] = React.useState(initialValues);
  const [error, setError] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);
  const [done, setDone] = React.useState(false);
  const [products, setProducts] = React.useState([]);

  const fetchProducts = async () => {
    fetch(GET_PRODUCTS_URL)
    .then(response => response.json())
    .then(data => setProducts(data))
    .catch(error => console.log(error))
  };

  if (!products || products.length == 0) fetchProducts();

  const {
    FORM_SUBMIT_TITLE_1,
    FORM_SUBMIT_TITLE_2,
    FORM_SUBMIT_TITLE_3,
    MAIN_TITLE,
    MAIN_SUBTITLE,
    MANDATORY_TEXT,
    EMAIL_ADDRESS_TITLE,
    PRODUCT_TITLE,
    PRODUCT_SUBTITLE,
    PROMO_CODE_TITLE,
    NAME_TITLE,
    PHONE_TITLE,
    PHONE_SUBTITLE,
    COUNTRY_TITLE,
    POSITION_TITLE,
    ORGANIZATION_TITLE,
    COMPANY_IDENTIFICATION_TITLE,
    COMPANY_IDENTIFICATION_SUBTITLE,
    FIELD_SELECT,
    FIELD_SUBMIT,
    FIELD_SUBMIT_ANOTHER,
    FORM_MANDATORY_FIELDS_ERROR,
    FORM_DEFAULT_ERROR,
    FORM_CHECK_YOUR_EMAIL,
    FORM_CHECK_YOUR_EMAIL_DETAIL
  } = locale;

  const handleLanguage = ({ target: { value } }) => {
    setLanguage(value);
  };

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    if (
      Object.keys(initialValues).some(
        (key) => form[key] === '' && !optionalFields.includes(key)
      )
    ) {
      console.log(Object.keys(initialValues).filter(
        (key) => form[key] === '' && !optionalFields.includes(key)
      ))
      setError(FORM_MANDATORY_FIELDS_ERROR);
      window.scrollTo(0, 0);
      return;
    }
    setLoading(true);

    form.productName = products.filter(p => p.id === form.ProductId)[0].name;

    await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(form),
      mode: 'no-cors',
    })
      .then(() => {
        setDone(true);
        setError(undefined);
      })
      .catch((error) => {
        setError(error.message || FORM_DEFAULT_ERROR);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Layout>
      <SEO title="De Lorenzo Software Download Form" />
      {loading && <SpinnerOverlay />}
      {done && (
        <CustomContainer maxWidth="sm">
          <CardBox>
            <CustomAlert severity="warning">
              <b>{FORM_CHECK_YOUR_EMAIL}</b>
              <br/>
              <small>{FORM_CHECK_YOUR_EMAIL_DETAIL}</small>
            </CustomAlert>
            <MainSubTitle>
              {`${FORM_SUBMIT_TITLE_1}, ${form.fullName}! ${FORM_SUBMIT_TITLE_2} ${form.email} ${FORM_SUBMIT_TITLE_3}`}
            </MainSubTitle>
            <SubmitButton onClick={() => window.location.reload()}>
              {FIELD_SUBMIT_ANOTHER}
            </SubmitButton>
          </CardBox>
        </CustomContainer>
      )}
      {!done && (
        <CustomContainer maxWidth="sm">
          {error && (
            <CustomAlert severity="error" onClose={() => setError(undefined)}>
              {error}
            </CustomAlert>
          )}
          <CardBox variant="outlined" elevation={3}>
            <LanguageSelector
              name="language"
              onChange={handleLanguage}
              value={language}
            >
              <ComboBoxOption value="en_US">English</ComboBoxOption>
              <ComboBoxOption value="pt_BR">Português</ComboBoxOption>
              <ComboBoxOption value="es_ES">Español</ComboBoxOption>
            </LanguageSelector>
            <MainTitle variant="h3">{MAIN_TITLE}</MainTitle>
            <MainSubTitle>{MAIN_SUBTITLE}</MainSubTitle>
            <p>
              <Mandatory /> {MANDATORY_TEXT}
            </p>
          </CardBox>
          <CardBox variant="outlined" elevation={3}>
            <Title>
              {EMAIL_ADDRESS_TITLE} <Mandatory />
            </Title>
            <InputText name="email" label="" onChange={handleChange} />
          </CardBox>

          <CardBox variant="outlined" elevation={3}>
            <Title>
              {PRODUCT_TITLE} <Mandatory />
            </Title>
            <Subtitle>{PRODUCT_SUBTITLE}</Subtitle>
            <FormControl variant="outlined">
              <ComboBox name="ProductId" onChange={handleChange}>
                <ComboBoxOption value="" disabled>
                  <em>{FIELD_SELECT}</em>
                </ComboBoxOption>
                {products.map((product) => (
                  <ComboBoxOption key={product.id} value={product.id}>
                    {product.name}
                  </ComboBoxOption>
                ))}
              </ComboBox>
            </FormControl>
          </CardBox>

          <CardBox variant="outlined" elevation={3}>
            <Title>{PROMO_CODE_TITLE}</Title>
            <InputText name="promoCode" label="" onChange={handleChange} />
          </CardBox>

          <CardBox variant="outlined" elevation={3}>
            <Title>
              {NAME_TITLE} <Mandatory />
            </Title>
            <InputText name="fullName" label="" onChange={handleChange} />
          </CardBox>

          <CardBox variant="outlined" elevation={3}>
            <Title>
              {PHONE_TITLE} <Mandatory />
            </Title>
            <Subtitle>{PHONE_SUBTITLE}</Subtitle>
            <InputText name="phone" label="" onChange={handleChange} />
          </CardBox>

          <CardBox variant="outlined" elevation={3}>
            <Title>
              {COUNTRY_TITLE} <Mandatory />
            </Title>
            <FormControl variant="outlined">
              <ComboBox name="country" onChange={handleChange}>
                <ComboBoxOption value="" disabled>
                  <em>{FIELD_SELECT}</em>
                </ComboBoxOption>
                {countries.map((country) => (
                  <ComboBoxOption key={country.code} value={country.code}>
                    {country.name}
                  </ComboBoxOption>
                ))}
              </ComboBox>
            </FormControl>
          </CardBox>

          <CardBox variant="outlined" elevation={3}>
            <Title>
              {POSITION_TITLE} <Mandatory />
            </Title>
            <FormControl variant="outlined">
              <ComboBox name="position" onChange={handleChange}>
                <ComboBoxOption value="" disabled>
                  <em>{FIELD_SELECT}</em>
                </ComboBoxOption>
                {positions.map((position) => (
                  <ComboBoxOption key={position.en_US} value={position.en_US}>
                    {position[language]}
                  </ComboBoxOption>
                ))}
              </ComboBox>
            </FormControl>
          </CardBox>

          <CardBox variant="outlined" elevation={3}>
            <Title>
              {ORGANIZATION_TITLE} <Mandatory />
            </Title>
            <FormControl variant="outlined">
              <ComboBox name="organization" onChange={handleChange}>
                <ComboBoxOption value="" disabled>
                  <em>{FIELD_SELECT}</em>
                </ComboBoxOption>
                {organizations.map((organization) => (
                  <ComboBoxOption
                    key={organization.en_US}
                    value={organization.en_US}
                  >
                    {organization[language]}
                  </ComboBoxOption>
                ))}
              </ComboBox>
            </FormControl>
          </CardBox>

          <CardBox variant="outlined" elevation={3}>
            <Title>
              {COMPANY_IDENTIFICATION_TITLE} <Mandatory />
            </Title>
            <Subtitle>{COMPANY_IDENTIFICATION_SUBTITLE}</Subtitle>
            <InputText name="companyId" label="" onChange={handleChange} />
          </CardBox>

          <SubmitButton onClick={handleSubmit} variant="contained">
            {FIELD_SUBMIT}
          </SubmitButton>
        </CustomContainer>
      )}
    </Layout>
  );
};

// TODO: proper handle CSS without these !important

const CustomContainer = styled(Container)`
  padding: 50px 0 !important;
`;

const CustomAlert = styled(Alert)`
  & > .MuiAlert-message {
    font-size: 1.6rem;
  }
  margin-bottom: 10px;
`;

const MainTitle = styled(Typography)`
  margin-bottom: 10px !important;
`;

const MainSubTitle = styled.p`
  font-size: 1.5rem;
  width: 100%;
  margin-bottom: 16px;
`;

const CardBox = styled(Paper)`
  width: 100%;
  margin: 0 auto;
  padding: 24px;
  position: relative;
  & + * {
    margin-top: 10px;
  }
`;

const LanguageSelector = styled(Select)`
  position: absolute !important;
  right: 15px !important;
  bottom: 15px !important;
`;

const Title = styled.p`
  font-size: 2rem;
  width: 100%;
  margin-bottom: 16px;
`;

const Mandatory = styled.span`
  color: #d93036;
  &::after {
    content: '*';
  }
`;

const Subtitle = styled.p`
  font-size: 1.25rem;
  width: 100%;
  margin-bottom: 16px;
  margin-top: -8px;
`;

const InputText = styled(TextField)`
  width: 50%;
  & .MuiInput-input {
    font-size: 1.4rem !important;
  }
`;

const ComboBox = styled(Select)`
  width: 200px;
  & > .MuiSelect-select {
    font-size: 1.4rem;
  }
  & > .MuiSelect-selectMenu {
    min-height: 0.5em;
  }
`;

const ComboBoxOption = styled(MenuItem)`
  font-size: 1.4rem !important;
`;

const SubmitButton = styled(Button)`
  margin-top: 15px !important;
  font-size: 1.4rem !important;
  background-color: #967300 !important;
  color: #fff !important;

  @media only screen and (max-width: 640px) {
    margin-left: 50% !important;
    transform: translateX(-50%);
    margin-top: 15px !important;
  }
`;
