export const MAIN_TITLE = {
  es_ES: 'De Lorenzo Software Download Form',
  en_US: 'De Lorenzo Software Download Form',
  pt_BR: 'De Lorenzo Software Download Form',
};

export const MAIN_SUBTITLE = {
  es_ES: 'Complete este formulario para tener acceso a nuestro software.',
  en_US: 'Fill this form to have access to our software.',
  pt_BR: 'Preencha este formulário para ter acesso ao nosso software.',
};

export const MANDATORY_TEXT = {
  es_ES: 'Obligatorio',
  en_US: 'Mandatory',
  pt_BR: 'Obrigatório',
};

export const EMAIL_ADDRESS_TITLE = {
  es_ES: 'Correo electrónico',
  en_US: 'E-mail address',
  pt_BR: 'Endereço de E-mail',
};

export const PRODUCT_TITLE = {
  es_ES: 'Producto',
  en_US: 'Product',
  pt_BR: 'Produto',
};

export const PRODUCT_SUBTITLE = {
  es_ES: 'Elija el software que desea descargar o activar',
  en_US: 'Choose the software you want to download or activate.',
  pt_BR: 'Escolha o software que você deseja baixar ou ativar.',
};

export const PROMO_CODE_TITLE = {
  es_ES: 'Código de prueba promocional',
  en_US: 'Promotional trial code',
  pt_BR: 'Código promocional',
};

export const NAME_TITLE = {
  es_ES: 'Nombre',
  en_US: 'Name',
  pt_BR: 'Nome',
};

export const PHONE_TITLE = {
  es_ES: 'Número de teléfono móvil',
  en_US: 'Mobile phone number',
  pt_BR: 'Telefone celular',
};

export const PHONE_SUBTITLE = {
  es_ES:
    'Si no tiene un teléfono móvil, puede utilizar un número de teléfono convencional.',
  en_US:
    "If you don't have a mobile phone, you can use a conventional phone number.",
  pt_BR:
    'Se você não tiver um telefone celular, você pode utilizar um número de telefone convencional.',
};

export const COUNTRY_TITLE = {
  es_ES: 'País',
  en_US: 'Country',
  pt_BR: 'País',
};

export const POSITION_TITLE = {
  es_ES: 'Cargo',
  en_US: 'Position',
  pt_BR: 'Posição',
};

export const ORGANIZATION_TITLE = {
  es_ES: 'Tipo de organización',
  en_US: 'Organization type',
  pt_BR: 'Tipo de organização',
};

export const COMPANY_IDENTIFICATION_TITLE = {
  es_ES: 'Identificación de la empresa o institución',
  en_US: 'Identification of the company or institution',
  pt_BR: 'Identificação da empresa ou instituição',
};

export const COMPANY_IDENTIFICATION_SUBTITLE = {
  es_ES:
    'Escriba el nombre de la empresa o institución en la que trabaja o estudia.',
  en_US:
    'Type the name of the company or institution where you work or study at.',
  pt_BR:
    'Preencha o nome da empresa ou instituição onde você trabalha ou estuda.',
};

export const FIELD_SELECT = {
  es_ES: 'Seleccione',
  en_US: 'Select',
  pt_BR: 'Selecione',
};

export const FIELD_SUBMIT = {
  es_ES: 'Enviar',
  en_US: 'Submit',
  pt_BR: 'Enviar',
};

export const FIELD_SUBMIT_ANOTHER = {
  es_ES: 'Llena otro',
  en_US: 'Fill out another one',
  pt_BR: 'Enviar outro',
};

export const FORM_SUBMIT_TITLE_1 = {
  es_ES: 'Su solicitud ha sido enviada',
  en_US: 'Your form has been submitted',
  pt_BR: 'O formulário foi enviado',
};

export const FORM_SUBMIT_TITLE_2 = {
  es_ES: 'Verifica',
  en_US: 'Check',
  pt_BR: 'Verifique',
};

export const FORM_SUBMIT_TITLE_3 = {
  es_ES: 'en unos momentos.',
  en_US: 'in a few moments.',
  pt_BR: 'em alguns instantes.',
};

export const FORM_MANDATORY_FIELDS_ERROR = {
  es_ES: 'Hay campos obligatorios vacíos. Consulta el formulario.',
  en_US: 'There are mandatory fields empty. Check the form.',
  pt_BR: 'Existem campos obrigatórios vazios. Cheque o formulário.',
};

export const FORM_DEFAULT_ERROR = {
  es_ES: 'Hubo un error. Inténtelo de nuevo más tarde.',
  en_US: 'There was an error. Please try again later.',
  pt_BR: 'Houve um erro. Tente novamente mais tarde.',
};

export const FORM_CHECK_YOUR_EMAIL = {
  es_ES: 'CHEQUE SU CORREO ELECTRÓNICO',
  en_US: 'CHECK YOUR EMAIL',
  pt_BR: 'VERIFIQUE SEU EMAIL',
};

export const FORM_CHECK_YOUR_EMAIL_DETAIL = {
  es_ES: 'Te acabamos de enviar un mensaje con las instrucciones de acceso. En caso de que no lo encuentres en tu bandeja de entrada, revisa tu correo no deseado.',
  en_US: "We've just sent you a message with access instructions. In case you don't find in your inbox, check your spam.",
  pt_BR: 'Acabamos de te enviar um email com as orientações de acesso. Caso não identifiques, verifique a caixa de spam.',
};