export default [
  {
    pt_BR: 'Estudante',
    es_ES: 'Estudiante',
    en_US: 'Student',
  },
  {
    pt_BR: 'Professor',
    es_ES: 'Profesor',
    en_US: 'Professor',
  },
  {
    pt_BR: 'Consultor',
    es_ES: 'Consultor',
    en_US: 'Consultant',
  },
  {
    pt_BR: 'Gerente ou Coordenador',
    es_ES: 'Gerente o Director',
    en_US: 'School/Company Manager',
  },
  {
    pt_BR: 'Governante',
    es_ES: 'Gobernante',
    en_US: 'Government Manager',
  },
];
